export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;
export const GA_TRACKING_ID_UNIVERSAL = process.env.NEXT_PUBLIC_GA_ID_UNIVERSAL;
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
export const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;

import mixpanel from "mixpanel-browser";

mixpanel.init(MIXPANEL_TOKEN, { debug: true, ignore_dnt: true });

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  window.gtag("config", GTM_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (
  { action, category, label, value, nonInteraction = false },
  extra
) => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  window.dataLayer.push({
    event: "custom_event",
    eventModel: {
      event_action: action,
      event_category: category,
      event_label: label,
      value: value,
      non_interaction: nonInteraction,
    },
    ...extra,
  });

  mixpanel.track(action, extra);
  if (window.sendinblue) {
    window.sendinblue.track(action, extra);
  }
};
